import React, { Component } from 'react';


export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: false
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

    render() {

        const show = (this.state.collapsed) ? "show" : "";

    return (
            <nav className="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
                <div className="container">
                    <a className="navbar-brand js-scroll-trigger" href="#page-top">Goldberry Translation</a>
                        <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation" onClick={this.toggleNavbar}>
                        Menu
                    <i className="fas fa-bars ml-1"></i>
                    </button>
                    <div className={"collapse navbar-collapse " + show} id="navbarResponsive">
                        <ul className="navbar-nav text-uppercase ml-auto">
                            <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#home">Home</a></li>
                            <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#about">About</a></li>
                            <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#rates">Rates</a></li>
                            <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#contact">Contact</a></li>
                        </ul>
                    </div>
                </div>
            </nav>
    );
  }
}
