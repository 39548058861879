import React, { Component } from 'react';

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = { name: '', email:'', message:'', submit:false, error: false };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangeMessage = this.handleChangeMessage.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        var data = {
            Name: this.state.name,
            email: this.state.email,
            message: this.state.message
        };

        fetch('api/contact', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(
                (result) => {
                    this.setState({ submit: true, name: '', email: '', message:'' });
                },
                (error) => {
                    this.setState({ error: true });
                }
            );

    }

    handleChangeName(event) {
        this.setState({ name: event.target.value });
    }


    handleChangeEmail(event) {
        this.setState({ email: event.target.value });
    }


    handleChangeMessage(event) {
        this.setState({ message: event.target.value });
    }



  render () {
    return (
      <div>

            
            <header className="masthead" id="home">
                <div className="container">
                    <div className="masthead-heading text-uppercase">Goldberry Translation</div>
                    <div className="text-center">
                        <p>Goldberry Translation is an established German to English translation service, specialising in legal and financial translation.

                            </p>
                    </div>
                    <div className="masthead-subheading">What can Goldberry Translation offer you?</div>
                    <div className="row text-center">
                        <div className="col-md-12 text-center">
                            <ul>
                                <li className="list-unstyled">High-quality German-English translation</li>
                                <li className="list-unstyled">Translations in Trados Studio 2021, MemoQ, XTM, Word, PowerPoint, Excel</li>
                                <li className="list-unstyled">Daily capacity: approx. 3,000-5,000 words (depending on subject matter)</li>
                                <li className="list-unstyled">Flexible working hours</li>
                                <li className="list-unstyled">Fast, reliable and accurate service</li>
                            </ul>
                        <p>Please <a href="#contact">contact</a> me if you have any queries or require a no-obligation quote for your translation needs.</p>
                        </div>
                        </div>
                </div>
            </header>
            
            <section className="page-section" id="about">
                <div className="container container-rounded">
                    <div className="text-center">
                        <h2 className="section-heading text-uppercase">About</h2>
                    </div>
                    <div className="row text-center">
                        <div className="col-md-12">
                            <p className="text-muted">I'm Debra Pasco, Proprietor/Translator of Goldberry Translation.  After 4 years working in-house as a German-English translator at <a href="http://www.surreytranslation.co.uk" target="new">Surrey Translation Bureau</a> in Farnham between 2001 and 2005, I decided to return to my roots in Bath and set up on my own as a freelance translator.</p>
                            <p className="text-muted">My achievements include winning the SFEDI "Buddy" Award in 2008 for mentoring others in business, teaching myself various CAT tools (including Trados Studio 2017, Wordfast, MemoQ and many companies' own in-house tools) and adapting my business to fit in around my family. My daughter is at secondary school and I appreciate the flexibility that freelancing brings so that I can be around when she needs me.</p>
                            <p className="text-muted">My core working hours are 9.00 a.m. to 4.30 p.m. UK time Monday to Friday, with evening and weekend work negotiable on demand to fit your deadlines.</p>
                                <p className="text-muted">Daily translation capacity: approx. 3,000-5,000 words per day, depending on subject matter.</p>
                            <p className="text-muted">I specialise in legal and financial translation, but have covered a huge range of topics since my career in translation began in 2001.</p>
                                <p className="text-muted">Please <a href="#contact">contact</a> me for a no-obligation quote for your translation needs.</p>
                        </div>

                    </div>
                </div>
            </section>
            
            <section className="page-section bg-light" id="rates">
                <div className="container container-rounded">
                    <div className="text-center">
                        <h2 className="section-heading text-uppercase">Rates</h2>
                    </div>
                    <div className="row text-center">
                        <div className="col-md-12">
                            <p className="text-muted">My rates are GBP 0.07 per word, based on source word count.</p>

                            <div className="text-center">
                                <table className="table table-smaller">
                                    <thead>
                                        <tr>
                                            <th colSpan="2">Trados matrix:</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>No match / 50-74% match</td>
                                            <td>100% of rate</td>
                                        </tr>
                                        <tr>
                                            <td>75-99% match</td>
                                            <td>50% of rate</td>
                                        </tr>
                                        <tr>
                                            <td>100% match, context match and repetitions</td>
                                            <td>20% of rate</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <p className="text-muted">Machine post-editing also available: rates negotiable on a job-specific basis, depending on whether light or heavy post-editing is required.</p>


                        </div>
                    </div>
                </div>
            </section>

            <section className="page-section" id="contact">
                <div className="container">
                    <div className="text-center">
                        <h2 className="section-heading text-uppercase">Contact</h2>
                        <h3 className="section-subheading text-muted">Want more information or a quote? Fill in your details below and I'll be in touch.</h3>
                    </div>
                    <form id="contactForm" name="sentMessage" onSubmit={this.handleSubmit}>
                        {this.state.submit && <Confirmation />}
                        {this.state.error && <Warning />}
                        <div className="row align-items-stretch mb-5">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input className="form-control" id="name" type="text" placeholder="Name *" required="required" data-validation-required-message="Please enter your name." value={this.state.name} onChange={this.handleChangeName}/>
                                    <p className="help-block text-danger"></p>
                                </div>
                                <div className="form-group">
                                    <input className="form-control" id="email" type="email" placeholder="Email *" required="required" data-validation-required-message="Please enter your email address." value={this.state.email} onChange={this.handleChangeEmail}/>
                                    <p className="help-block text-danger"></p>
                                </div>
                                <div className="form-group mb-md-0">
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group form-group-textarea mb-md-0">
                                    <textarea className="form-control" id="message" placeholder="Message *" required="required" data-validation-required-message="Please enter a message." value={this.state.message} onChange={this.handleChangeMessage}></textarea>
                                    <p className="help-block text-danger"></p>
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <div id="success"></div>
                            <button className="btn btn-primary btn-xl text-uppercase" id="sendMessageButton" type="submit" >Send Message</button>
                            <p className="text-muted small">By submitting this form you consent to sending your details via email to Goldberry Translation.</p>
                        </div>
                    </form>
                </div>
            </section>
            
            <footer className="footer py-4">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 text-lg-left"></div>
                        
                        <div className="col-lg-4 my-3 my-lg-0">
                            <a className="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/in/debra-pasco-7482215/?originalSubdomain=uk" target="new"><i className="fab fa-linkedin-in"></i></a>
                        </div>
                        <div className="col-lg-4 text-lg-right">
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-4 text-lg-left"></div>
                        <div className="col-lg-4 my-3 my-lg-0">
                            <p>Copyright &#169; Goldberry Translation 2020</p>
                            <p className="small">Site designed and built by S H Wright Consulting Ltd.</p>
                        </div>
                        <div className="col-lg-4 text-lg-right">
                        </div>
                    </div>
                </div>
            </footer>


            
            
            
            <script src="assets/mail/jqBootstrapValidation.js"></script>
            <script src="assets/mail/contact_me.js"></script>
            
            <script src="src/scripts.js"></script>
      </div>
    );
    }



}

class NewRow extends React.Component {
    render() {
        return (
            <div className="row align-items-stretch mb-5">
                <div className="col-md-12">
                    { this.props.message}
                </div>
            </div>
            )
    }
}

class Confirmation extends React.Component {
    render() {

        return (
            <NewRow message={<p className="form-message-submit text-muted">Thank you for your message - I will be in touch soon.</p>} />
        )
    }
}

class Warning extends React.Component {
    render() {
        return (
            <NewRow message={<p className="form-message-error text-muted">An error has occured, please try again.</p>} />
        )
    }
}
